$text-color: #101828;
$text-color-secondary: #667085;
$form-dark: #060507;
$form-light: #14374D;
$color-luxonis: #4e38ed;
@mixin form-bg {
  background: #fff; //linear-gradient(to bottom, $form-dark, $form-light, $form-dark);
  border-radius: 10px;
  border: 1px solid #eaecf0;
  width: 90vw;
  //box-shadow: 0 0 10px 2px rgba(53, 69, 79, 0.7)
}

.btn-outline-luxonis {
  color: $color-luxonis;
  border-color: $color-luxonis;
}

.btn-outline-luxonis:hover {
  background-color: $color-luxonis;
  color: white;
}

a {
  color: $text-color;
  text-decoration: none;

  &:visited {
    color: $text-color;
    text-decoration: none;
    opacity: 0.42;
  }

  &:hover {
    opacity: 0.7;
    text-decoration: underline;
  }

  &:active {
    opacity: 1;
    text-decoration: none;
  }
}

/*textarea {
  background-color: #f5f5f5;
  border-color: #ccc;
  outline-color: #ccc;
  outline-style: none;
  box-shadow: none;
  color: $text-color-secondary;
  font-family: 'Roboto', 'Noto', sans-serif;
  font-size: 14px;
}*/

/*input, select {
  background-color: #f5f5f5;
  border-color: #ccc;
  outline-color: #ccc;
  outline-style: none;
  box-shadow: none;
  color: $text-color-secondary;
  font-family: 'Roboto', 'Noto', sans-serif;
  font-size: 14px;
}*/
input {
  background-color: #f5f5f5;
  border-color: #ccc;
  outline-color: #ccc;
  outline-style: none;
  box-shadow: none;
  color: $text-color-secondary;
  font-family: 'Roboto', 'Noto', sans-serif;
  font-size: 14px;
}

//select {
//  font-size: 14px;
//}

.form-control, input, textarea {
  background-color: #f5f5f5;
  font-size: 14px;
}

.App {
  color: $text-color;

  .particles-canvas {
    position: absolute !important;
    left: 0;
    top: 0;
    filter: blur(4px);
  }

  text-align: center;
  background-size: cover;
  background-color: #f5f5f5;;
  background-position: center center;
  position: relative;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  overflow-y: scroll;

  .jumbo {

    h1 img {
      height: 60px;
      margin-right: 15px;
    }


    h1 {
      margin: 40px 0;
      font-size: 56px;
      font-weight: 700;
      line-height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h3 {
      margin-bottom: 50px;
      color: $text-color-secondary;
    }
  }

  .type-chooser {
    @include form-bg;
    padding: 40px 10px 10px 10px;

    .cta {
      h2 {
        font-weight: 700;
        font-size: 28px;
      }

      margin-bottom: 30px;
    }

    .version-choices, .model-choices {
      margin-bottom: 40px;

      .btn + .btn {
        margin-left: 30px;
      }

      .btn {
        background-color: #f6fcfe;
        border-color: $color-luxonis;
        margin-bottom: 10px;
        color: $text-color-secondary;
        font-size: 16px;

        &:hover {
          background-color: $color-luxonis;
          color: #fff;
        }

        &:active, &.active {
          background-color: $color-luxonis;
          color: #fff;
        }

        &.default {
          position: relative;
          border-width: 3px;

          .default-label {
            position: absolute;
            top: 45px;
            left: 0px;
            color: gray;

            p {
              font-size: 11px;
            }
          }
        }
      }
    }
  }

  .info {
    font-size: 11px;
    font-weight: 300;
    line-height: 40px;

    a {
      text-decoration: underline;
      text-orientation: sideways;
    }
  }

  .content {
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .carousel-inner {
    padding: 5px;
  }

  .params-form {
    display: flex;
    height: 420px;
    @include form-bg;
    width: 880px;
    transition: width 500ms ease-in-out;

    &.expanded {
      width: 95vw; //1550px
    }

    .upper-border, .lower-border {
      height: 40px;
      background: #fff;
      overflow: hidden;

      a {
        text-decoration: underline;
      }
    }

    .upper-border {
      font-size: 16px;
      line-height: 40px;
      font-weight: bold;
    }

    .lower-border {
      font-size: 11px;
      font-weight: 300;
      line-height: 40px;
    }

    .form, .steps, .advanced-options {
      height: 330px;
    }

    .params-form-paths {
      width: 350px;
      border-right: 2px solid #eaecf0;

      .upper-border {
        border-radius: 20px 0 0 0;
      }

      .lower-border {
        border-radius: 0 0 0 20px;

        button {
          font-size: 11px;
          padding: 0 0 0 3px;
          vertical-align: baseline;
          color: $text-color;
          text-decoration: underline;
          font-weight: 300;
        }
      }

      .form {
        padding: 25px;
        height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        input {
          width: 100%;
        }
        label {
          display: block;
        }
        select {
          padding: 5px 5px;
          width: 100%;
        }
      }
    }

    .params-form-steps {
      width: 500px;
      border-right: 2px solid #eaecf0;

      .steps {
        padding: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;

        .step {
          position: relative;
          display: flex;
          height: 80px;

          .step-label {
            //position: absolute;
            //top: 10px;
            //left: 30px;
            font-size: 40px;
            font-family: "Helvetica Neue", sans-serif;
          }

          .step-descr {
            text-align: left;
            padding: 15px 20px;

            .title {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 5px;
            }

            .subtitle {
              font-size: 12px;
              margin: 0;
            }
          }

          img {
            width: 80px;
            height: 80px;
          }
        }
      }

      .lower-border {
        .btn {
          margin-bottom: 10px;
        }
        .btn + .btn {
          margin-left: 100px;
        }
        .btn[type=submit] {
          span + span {
            margin-left: 5px;
          }
        }
      }
    }

    .params-form-advanced {
      width: 30px;
      overflow: hidden;
      position: relative;
      color: rgba($text-color, 0);
      transition: width 500ms ease-in-out, color 500ms ease-in-out;

      .upper-border {
        border-radius: 0 20px 0 0;
      }

      .lower-border {
        border-radius: 0 0 20px 0;
      }

      &.expanded {
        width: 700px;
        color: rgba($text-color, 1);
      }

      .expander {
        border-radius: 0 0 10px 10px;
        width: 420px;
        height: 30px;
        background: $color-luxonis;
        color: rgba(#fff, 1);
        cursor: pointer;
        position: absolute;
        line-height: 30px;
        left: calc(100% - 15px);
        top: 50%;
        transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      }

      .advanced-options {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 35px 55px 5px 25px;

        .advanced-option {
          text-align: left;
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 5px;
          label {
            span {
              font-weight: 700;
            }
          }
          .advanced-option-input-shaves-ticks {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.modal-content {
  background-color: #fff;
  color: $text-color;

  .modal-header, .modal-footer {
    background-color: $color-luxonis;
    color: white;
  }

  .modal-header {
    border-bottom: 1px solid #eaecf0;

    .modal-title {
      font-weight: 700;
    }
    .close {
      color: white;
      opacity: unset;
    }
  }

  .modal-body {
    div + div {
      margin-top: 30px;
    }
    pre {
      margin-top: 10px;
      background-color: #f5f5f5;
      color: $text-color;
      font-family: "Courier New", monospace;
      padding: 10px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .modal-footer {
    border-top: 1px solid #eaecf0;
    display: flex;
    justify-content: space-between;
  }
}

#api-docs {
  position: fixed;
  right: 0;
  top: 0;
  padding: 10px 20px;
  border-radius: 0 0 0 30px;
  span + span {
    margin-left: 10px;
  }
}

#docs-modal {
  .code-section + .code-section {
    margin-top: 40px;
  }
}


